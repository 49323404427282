import React, {useState, useEffect} from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import Config from '../config';
import {Button} from '@rmwc/button';
import {TextField} from '@rmwc/textfield';
import {GridCell, GridRow} from '@rmwc/grid';
import {CircularProgress} from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';
import { format } from "@buttercup/react-formatted-input";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import * as resources from '../resources'

const InitialForm = ({callback}) => {

  const { executeRecaptcha } = useGoogleReCaptcha();
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Captcha on start - just for monitoring
    (async () => await executeRecaptcha("home_request"))();
  }, []);

  const schema = yup.object({
    agreementNum: yup.string()
      .required(),
    initials: yup.string()
      .required()
      .min(4)
      .max(4)
  });

  const formik = useFormik({
    initialValues: {
      agreementNum: '',
      initials: ''
    },
    onSubmit: values => {
      (async () => {

        const captcha = await executeRecaptcha("submit_request");

        setLoading(true);
        setError(false);

        try {
          let response = await fetch(Config().API_URL + '/links', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              agreement_num: values.agreementNum,
              initials: values.initials.replace('-',''),
              gc_token: captcha
            })
          });

          if (response.status === 200){
            let data = await response.json();
            callback(data.request_id, data.agreement_num, data.additional_data);
          } else {
            setError(true);
          }
        } catch (err) {
          setError(true);
        } finally {
          setLoading(false);
        }

      })();
    },
    validationSchema: schema
  });

  const letterMask = /[^_\s-]/iu;
  const initialsFormat = [
    { char: letterMask, repeat: 2 },
    { exactly: "-" },
    { char: letterMask, repeat: 2 }
  ];

  return (
        <form onSubmit={formik.handleSubmit}>
          
          <GridRow className="cg-row">
            <GridCell span={6}>
              <GridRow className="cg-row-inner">
                <GridCell span={12}>
                  <TextField 
                    invalid={formik.touched.agreementNum && !!formik.errors.agreementNum}
                    outlined 
                    name="agreementNum"
                    onChange={formik.handleChange}
                    value={formik.values.agreementNum}
                    label={resources.S1_Agreement_Number} />
                </GridCell>
              </GridRow>
              <GridRow className="cg-row-inner">
                <GridCell span={12}>
                  <TextField
                    name="initials"
                    invalid={formik.touched.initials && !!formik.errors.initials}
                    outlined
                    maxLength={5}
                    label={resources.S1_Initials}
                    onChange={(e) => {
                      e.target.value = format(e.target.value, initialsFormat).raw;
                      formik.handleChange(e);
                    }}
                    value={format(formik.values.initials, initialsFormat).formatted}
                  />
                  
                  <div className="mdc-text-field-helper-line">
                      <div className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent" id="my-helper-id" aria-hidden="true">{resources.S1_Instruction}
                      .</div>
                  </div>
                </GridCell>
              </GridRow>
            </GridCell>

            <GridCell span={6}>
              <div className="error-info" style={{display: error ? 'block' : 'none'}}>
                <b>{resources.S1_Result_Not_Found_Info}</b><br />
                {resources.S1_Result_Not_Found_Processed}<br />
                <b>{resources.S1_Result_Not_Found_TryAgain}</b>
              </div>
            </GridCell>
          </GridRow>

          <GridRow className="cg-row">
            <GridCell span={6}>
              <Button 
                disabled={loading}
                unelevated className="mdc-button" icon={loading && <CircularProgress theme="primary" />}
              >
                <div className="mdc-button__ripple"></div>
                <span className="mdc-button__label">
                  {resources.S1_Submit}
                </span>
              </Button>
            </GridCell>
          </GridRow>
        </form>
      );
};

export default InitialForm;