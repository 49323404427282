import React, {useState, useEffect} from 'react';
import Config from '../config';
import {Button} from '@rmwc/button';
import {GridCell, GridRow} from '@rmwc/grid';
import {CircularProgress} from '@rmwc/circular-progress';
import '@rmwc/circular-progress/circular-progress.css';
import PinForm from './PinForm';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import * as resources from '../resources'
import { format } from '@buttercup/react-formatted-input';

const ConfirmForm = ({requestId, agreementNum, additionalData}) => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const editableFields = [2,4,7,8];
  const [loading, setLoading] = useState(false);
  const [shortPin, setShortPin] = useState('');
  const [error, setError] = useState(false);
  const [paid, setPaid] = useState(false);
  const [valid, setValid] = useState(true);
  const [info, setInfo] = useState({});

  useEffect(() => {
    // Captcha on start - just for monitoring
    (async () => await executeRecaptcha("home_confirm"))();

    if (additionalData){
      let addDataTmp = {};
      if (additionalData.repfreq?.toLowerCase() === 'mth' || additionalData.repfreq?.toLowerCase() === 'wk'){
        addDataTmp.isMonthly = additionalData.repfreq?.toLowerCase() === 'mth';
        addDataTmp.term = additionalData.term;
        addDataTmp.riv = additionalData.riv;
      }
      addDataTmp.repDate = additionalData.repdate;
      addDataTmp.repAccount = additionalData.repaccnt;

      setInfo(addDataTmp);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    (async () => {

      if (shortPin.length != 4){
        setValid(false);
        return;
      }
      setValid(true);

      const captcha = await executeRecaptcha("submit_confirm");

      setLoading(true);
      setError(false);
      setPaid(false);

      try {
        let response = await fetch(Config().API_URL + '/links/' + requestId + '/confirm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            pin_short: shortPin,
            gc_token: captcha
          })
        });

        if (response.status === 200){
          let data = await response.json();
          window.top.location.href = data.url;
        } else if (response.status === 418){
          setPaid(true);
          setLoading(false);
        } else {
          setValid(false);
          setError(true);
          setLoading(false);
        }
      } catch (err) {
        setError(true);
        setLoading(false);
      }

    })();
  }

  const bankAccountFormat = [
    { char: /\d/, repeat: 2 },
    { exactly: " " },
    { char: /\d/, repeat: 4 },
    { exactly: " " },
    { char: /\d/, repeat: 4 },
    { exactly: " " },
    { char: /\d/, repeat: 4 },
    { exactly: " " },
    { char: /\d/, repeat: 4 },
    { exactly: " " },
    { char: /\d/, repeat: 4 },
    { exactly: " " },
    { char: /\d/, repeat: 4 }
  ];

  return (
    <form onSubmit={handleSubmit}>
      <GridRow className="step2">
        <GridCell desktop={6} phone={12} className="form-container item">
          <GridRow className="cg-row">
            <GridCell span={12}>
              <p>{resources.S2_Pin_Number}</p>
              <PinForm fields={editableFields} onChange={setShortPin} valid={valid} />
            </GridCell>
          </GridRow>

          <GridRow className="cg-row">
            <GridCell span={8}>
              <div className="error-info" style={{display: error ? 'block' : 'none'}}>
                <b>{resources.S2_Result_Invalid_Data}</b>
              </div>
              <div className="error-info" style={{display: paid ? 'block' : 'none'}}>
                <b>{resources.S2_Result_Transaction_Paid}</b>
              </div>
            </GridCell>
          </GridRow>

          <GridRow className="cg-row">
            <GridCell span={8}>
              <Button 
                disabled={loading}
                style={{width: '280px'}}
                unelevated className="mdc-button" icon={loading && <CircularProgress theme="primary" />}
              >
                <div className="mdc-button__ripple"></div>
                <span className="mdc-button__label">
                  {resources.S2_Proceed_To_Transaction}
                </span>
              </Button>
            </GridCell>
          </GridRow>

        </GridCell>

        <GridCell desktop={6} phone={12} className="info-container item">
          <GridRow className="cg-row">
            <GridCell span={12} className="agreement-info-box">
              <p>Numer umowy pożyczki: <b>{agreementNum}</b></p>
              {
              info.term &&
              <><p>Okres trwania pożyczki: <b>{info.term} {info.isMonthly ? 'msc.' : 'tyg.'}</b></p></>
              }
              {
              info.riv &&
              <>
                <p>
                  Wysokość {info.isMonthly ? 'miesięcznej' : 'tygodniowej'} raty: <b>{info.riv} PLN</b>
                  {
                  info.repDate &&
                  <><br/><span className="small">Data spłaty najbliższej raty: {info.repDate} r.</span></>
                  }
                </p></>
              }
              {
              info.repAccount &&
              <><p>Numer konta do wpłat:<br/><b>{format(info.repAccount, bankAccountFormat).formatted}</b></p></>
              }
            </GridCell>
          </GridRow>
        </GridCell>

      </GridRow>
    </form>
  );
};

export default ConfirmForm;