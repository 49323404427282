const Config = () => {

    const API_URL = window['runConfig'].apiUrl;
    const GC_SITE_KEY = window['runConfig'].gcSiteKey;
    const HOT_JAR_ID = window['runConfig'].hotJarProjectId;
    const HOT_JAR_VERSION = window['runConfig'].hotJarVersionId;

    if (process.env.NODE_ENV === 'development'){
        return {
            API_URL: process.env.REACT_APP_API_URL,
            GC_SITE_KEY: process.env.REACT_APP_GC_SITE_KEY,
            HOT_JAR_ID: -1,
            HOT_JAR_VERSION: 7
        }
    }

    return {
        API_URL,
        GC_SITE_KEY,
        HOT_JAR_ID,
        HOT_JAR_VERSION
    }

}

export default Config;