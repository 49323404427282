import React, { Component } from 'react';
import PropTypes from 'prop-types';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40
};

export default class PinForm extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['text', 'number']),
    onChange: PropTypes.func,
    onComplete: PropTypes.func,
    fields: PropTypes.arrayOf(PropTypes.number),
    loading: PropTypes.bool,
    title: PropTypes.string,
    fieldWidth: PropTypes.number,
    fieldHeight: PropTypes.number,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    valid: PropTypes.bool
  };

  static defaultProps = {
    type: 'number',
    fields: [1,2,3,4],
    autoFocus: true,
    disabled: false,
    required: false,
    valid: true
  };

  constructor(props) {
    super(props);
    const { fields, values } = props;
    let vals;
    let autoFocusIndex = 0;
    if (values && values.length) {
      vals = [];
      for (let i = 0; i < fields.length; i++) {
        vals.push(values[i] || '');
      }
      autoFocusIndex = values.length >= fields.length ? 0 : values.length;
    } else {
      vals = Array(fields.length).fill('');
    }
    this.state = { values: vals, autoFocusIndex };

    this.iRefs = [];
    for (let i = 0; i < fields.length; i++) {
      this.iRefs.push(React.createRef());
    }
    this.id = +new Date();
  }

  /**
   * Clear all field value & focus first field
   */
  __clearvalues__ = () => {
    const { fields } = this.props;
    this.setState({ values: Array(fields.length).fill('') });
    this.iRefs[0].current.focus();
  };

  triggerChange = (values = this.state.values) => {
    const { onChange, onComplete, fields } = this.props;
    const val = values.join('');
    onChange && onChange(val);
    if (onComplete && val.length >= fields.length) {
      onComplete(val);
    }
  };

  onChange = e => {
    const index = parseInt(e.target.dataset.id);
    if (this.props.type === 'number') {
      e.target.value = e.target.value.replace(/[^\d]/gi, '');
    }
    if (
      e.target.value === '' ||
      (this.props.type === 'number' && !e.target.validity.valid)
    ) {
      return;
    }
    const { fields } = this.props;
    let next;
    const value = e.target.value;
    let { values } = this.state;
    values = Object.assign([], values);
    if (value.length > 1) {
      let nextIndex = value.length + index - 1;
      if (nextIndex >= fields.length) {
        nextIndex = fields.length - 1;
      }
      next = this.iRefs[nextIndex];
      const split = value.split('');
      split.forEach((item, i) => {
        const cursor = index + i;
        if (cursor < fields.length) {
          values[cursor] = item;
        }
      });
      this.setState({ values });
    } else {
      next = this.iRefs[index + 1];
      values[index] = value;
      this.setState({ values });
    }

    if (next) {
      next.current.focus();
      next.current.select();
    }

    this.triggerChange(values);
  };

  onKeyDown = e => {
    const index = parseInt(e.target.dataset.id);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev = this.iRefs[prevIndex];
    const next = this.iRefs[nextIndex];
    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault();
        const vals = [...this.state.values];
        if (this.state.values[index]) {
          vals[index] = '';
          this.setState({ values: vals });
          this.triggerChange(vals);
        } else if (prev) {
          vals[prevIndex] = '';
          prev.current.focus();
          this.setState({ values: vals });
          this.triggerChange(vals);
        }
        break;
      case KEY_CODE.left:
        e.preventDefault();
        if (prev) {
          prev.current.focus();
        }
        break;
      case KEY_CODE.right:
        e.preventDefault();
        if (next) {
          next.current.focus();
        }
        break;
      case KEY_CODE.up:
      case KEY_CODE.down:
        e.preventDefault();
        break;
      default:
        // this.handleKeys[index] = true;
        break;
    }
  };

  onFocus = e => {
    e.target.select(e);
  };

  render() {
    const { values, autoFocusIndex } = this.state;
    const cells = Array(11).fill('');
    cells.forEach((v, i) => {
        cells[i] = this.props.fields.indexOf(i+1);
    });
    const {
      fieldHeight,
      fieldWidth,
      autoFocus,
    } = this.props;
    
    return (
        <div className="pesel-container">
            {cells.map((value, index) => (
            <>
            {
                value >= 0 &&
                <div className="text-field-container pesel-number">
                  <div>{index+1}</div>
                  <div className={`mdc-text-field text-field mdc-text-field--outlined mdc-text-field--no-label ${this.props.valid ? '' : 'mdc-text-field--invalid'}`}>
                      <input 
                          className="mdc-text-field__input pesel-input"
                          pattern="[0-9]*"
                          autoFocus={autoFocus && value === autoFocusIndex}
                          key={`${this.id}-${value}`}
                          data-id={value}
                          value={values[value]}
                          ref={this.iRefs[value]}
                          onChange={this.onChange}
                          onKeyDown={this.onKeyDown}
                          onFocus={this.onFocus}
                          disabled={false}
                      />
                      <div className="mdc-notched-outline mdc-notched-outline--no-label">
                          <div className="mdc-notched-outline__leading"></div>
                          <div className="mdc-notched-outline__trailing"></div>
                      </div>
                  </div>
              </div>
            }
            {
                value < 0 &&
                <div className="text-field-container pesel-number">
                  <div>{index+1}</div>
                  <div className="mdc-text-field text-field mdc-text-field--outlined mdc-text-field--no-label mdc-text-field--disabled">
                      <input 
                          className="mdc-text-field__input pesel-input"
                          key={`${this.id}-${index}-nope`}
                          disabled={true}
                      />
                      <div className="mdc-notched-outline mdc-notched-outline--no-label">
                          <div className="mdc-notched-outline__leading"></div>
                          <div className="mdc-notched-outline__trailing"></div>
                      </div>
                  </div>
              </div>
            }
            </>
            ))
        }
        </div>
    );
  }
}