import React from 'react';

const BrowserNotSupported = () => {
    return (
        <div style={{padding: "100px"}}>
            <p>Twoja przeglądarka internetowa nie jest aktualnie wspierana w procesie Pay-By-Link.</p>
            <p>Przepraszamy!</p>
        </div>
    );
};

export default BrowserNotSupported;