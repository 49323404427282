import React from 'react';

export const Form_Header =(<span>Zapłać ratę pożyczki szybko i wygodnie</span>);
export const Form_Instructions = (<p>Podaj nam swój numer pożyczki, znajdziesz go na umowie, oraz dwie pierwsze litery imienia i dwie pierwsze litery nazwiska. <br /> Poprosimy cię o potwierdzenie tożsamości losowo wybranymi cyframi z numeru PESEL</p>);
export const S1_Agreement_Number = "Podaj numer umowy pożyczki"
export const S1_Initials = "Podaj dwie pierwsze litery imienia i nazwiska"
export const S1_Result_Not_Found_Info = "Niestety nie odnaleźliśmy Twojej umowy na podstawie podanych informacji."
export const S1_Result_Not_Found_Processed = "Jeżeli dane są prawidłowe, oznacza to, że wciąż pracujemy nad udostępnieniem płatności online również dla Ciebie!"
export const S1_Result_Not_Found_TryAgain = "Spróbuj ponownie później!"
export const S1_Submit = "Dalej"
export const S1_Instruction = (<p>Proszę podać dwie pierwsze litery imienia, a następnie dwie litery nazwiska. Znak "-" wstawi się automatycznie. W przypadku nazwisk dwuczłonowych, należy podać dwie pierwsze litery pierwszego nazwiska.</p>);
export const S2_Pin_Number = "Podaj wskazane cyfry numeru PESEL"
export const S2_Result_Invalid_Data = "Podałeś nam błędne dane. Sprawdź i wprowadź je ponownie."
export const S2_Result_Transaction_Paid = "Wybrana płatność została już zrealizowana."
export const S2_Proceed_To_Transaction = "Zapłać online"