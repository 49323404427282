import React, { useState } from 'react';
import InitialForm from './components/InitialForm';
import ConfirmForm from './components/ConfirmForm';
import {Grid, GridCell, GridRow} from '@rmwc/grid';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import Config from './config';
import * as resources from './resources'
import {isIE, browserVersion} from 'react-device-detect';
import BrowserNotSupported from './components/BrowserNotSupported';
import HotJar from "./react-hot-jar"


const config = Config();
HotJar.initialize(config.HOT_JAR_ID,config.HOT_JAR_VERSION);

function App() {

  const [requestId, setRequestId] = useState();
  const [agreementNum, setAgreementNum] = useState();
  const [additionalData, setAdditionalData] = useState();

  const [step, setStep] = useState(0);
  
  const initialFormCallback = (requestId, agreementNum, additionalData) => {
    setRequestId(requestId);
    setAgreementNum(agreementNum);
    setAdditionalData(additionalData);
    setStep(c => ++c);
  }

  const steps = [
    <InitialForm callback={initialFormCallback}/>,
    <ConfirmForm requestId={requestId} agreementNum={agreementNum} additionalData={additionalData}/>
  ]

  if (isIE && parseInt(browserVersion) <= 11){
    return (
      <BrowserNotSupported />
    )
  } else {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={Config().GC_SITE_KEY}>
        <div className="mdc-typography--body2">
          <Grid>
            <GridRow className="cg-row">
              <GridCell span={11}>
                <h1 class="pbl_header">{resources.Form_Header}</h1>
                {step === 0 && resources.Form_Instructions}
              </GridCell>
            </GridRow>
            {steps[step]}
          </Grid>
        </div>
      </GoogleReCaptchaProvider>
    );
  }
}

export default App;
